.tabs {
  display: flex;
  justify-content: center;
  width: fit-content;

  margin: 0 auto 1rem auto;
  padding: 0.5rem 0;

  background-color: #1c1a1a;
  border-radius: 0.5rem;

  li {
    position: relative;

    list-style: none;

    font-weight: 600;
    font-size: 1.2rem;

    cursor: pointer;

    margin: 0 0.5rem;

    p{
      position: relative;

      padding: 0.5rem 0.75rem;
      margin: 0;

      z-index: 0;
    }
  }
}

.slider {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 0.5rem;

  background-color: #353333;

  z-index: 0;
}