#vk_auth {
    iframe:not(:first-child) {
        display: none;
    }

    iframe:first-child {
        display: block;
    }
}

iframe {

    background: red

}

._2\+yQJiWG4 {
    background: red
}