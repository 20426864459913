@tailwind base;
@tailwind components;
@tailwind utilities;

@import "authSelect";
@import "yandex";
@import "auth";

body {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;

    width: 100vw;
    height: 100vh;
}


.continue {
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.75rem;
    }

    input, .p-dropdown {
        width: 270px !important;
    }

    button {
        width: fit-content;
        align-self: flex-end;
    }
}

.field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

button {
    margin-top: 0.75rem;
}

.field-checkbox label {
    padding-left: 0.75rem;
}

.perPlug {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;

    a {
        color: #cc2626
    }
}

.p-button {
    font-weight: 600;
    text-align: center !important;
    font-size: 1.1rem;
}


.error-message {
    margin: auto;
    width: fit-content;

    font-size: 1.1rem;
    font-weight: 600;
    color: #e24c4c;
}