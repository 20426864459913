.yaButton {
  display: flex;
  justify-content: center;
  gap: 12px;

  background: #000000 !important;
  border: none;
  border-radius: 12px;

  padding: 12px 24px;

  img:last-child {
    padding-top: 3px;
  }

  img {
    zoom: 1.3
  }
}